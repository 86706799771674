import { __decorate } from 'tslib';
import { Validators } from '@angular/forms';
import { set } from 'lodash';
function ValidationMessages(messages) {
  return target => {
    const prototype = Validators;
    const properties = Object.keys(messages).reduce((acc, validatorKey) => {
      const descriptor = Object.getOwnPropertyDescriptor(prototype, validatorKey);
      if (!descriptor) {
        return acc;
      }
      const baseValidator = descriptor.value;
      const childValidator = target[validatorKey];
      const hasExtraParams = childValidator.length > baseValidator.length;
      acc[validatorKey] = {
        value: function (...args) {
          let message = args[childValidator.length - 1] || messages[validatorKey];
          const validatorFunc = hasExtraParams ? baseValidator.apply(this, args) : baseValidator.bind(this);
          const newValidatorFunc = function (control) {
            const result = validatorFunc(control);
            if (!result) {
              return null;
            }
            const errorKey = Object.keys(result)[0];
            message = formatErrorMessage(result[errorKey], message);
            set(result, `q9_${errorKey}.message`, message);
            return result;
          };
          Object.defineProperty(newValidatorFunc, 'name', {
            value: baseValidator.name,
            writable: false
          });
          return newValidatorFunc;
        }
      };
      return acc;
    }, {});
    Object.defineProperties(target, properties);
    return target;
  };
}
function formatErrorMessage(params, message) {
  if (typeof params === 'object') {
    const keys = Object.keys(params).join('|');
    const regex = new RegExp(`{(${keys})}`, 'gi');
    message = message.replace(regex, (match, key) => params[key]);
  }
  return message;
}

//NOTE: Based on Angular default validators
let Q9Validators = class Q9Validators {
  static {
    this.compose = Validators.compose;
  }
  static {
    this.composeAsync = Validators.composeAsync;
  }
  static min(min, message) {
    return Validators.min(min);
  }
  static max(max, message) {
    return Validators.max(max);
  }
  static required(message) {
    return Validators.required;
  }
  static requiredTrue(message) {
    return Validators.requiredTrue;
  }
  static email(message) {
    return Validators.email;
  }
  static minLength(minLength, message) {
    return Validators.minLength(minLength);
  }
  static maxLength(maxLength, message) {
    return Validators.maxLength(maxLength);
  }
  static pattern(pattern, message) {
    return Validators.pattern(pattern);
  }
  static nullValidator(message) {
    return Validators.nullValidator;
  }
};
Q9Validators = __decorate([ValidationMessages({
  min: 'This field must be greater than or equal to {min}.',
  max: 'This field must be less than or equal to {max}.',
  minLength: 'At least {requiredLength} characters required.',
  maxLength: 'Up to {requiredLength} characters allowed.',
  required: 'This field is required.',
  requiredTrue: 'This field must be true.',
  email: 'Please enter a valid email address.',
  pattern: `Value {actualValue} doesn't match the requested format.`,
  nullValidator: 'This field cannot be null.'
})], Q9Validators);

/**
 * Generated bundle index. Do not edit.
 */

export { Q9Validators };
